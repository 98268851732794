<template>
  <div class="upload" style="text-align: center">
    <el-dialog
      title="导入"
      :visible.sync="dialog"
      width="500px"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-upload
        class="upload-demo"
        drag
        ref="upload"
        :action="url"
        :limit="1"
        :auto-upload="false"
        :on-preview="handlePreview"
        :before-upload="beforeUpload"
        :on-success="handleAvatarSuccess"
        :on-progress="progress"
        :headers="{
          Authorization: `Bearer ${$store.getters.token}`,
        }"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <div
        style="margin-top: 10px; cursor: pointer; color: #409eff"
        @click="handleDown"
      >
        下载{{ $store.getters.formName }}导入模板
      </div>
      <el-button
        type=""
        @click="requestInfo"
        size="small"
        style="margin-top: 20px"
        :loading="upLoading"
        >确定上传</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import { importTemplate, pageViewListErrorReport } from "@/api/process.js";
import { download } from "@/utils/export.js";

export default {
  props: {
    upLoadFileDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      url: "",
      upLoading: false,
    };
  },
  created() {
    this.url = `${process.env.VUE_APP_BASE_URL}/cloud-admin/form-data/upload?formId=${this.$route.query.code}`;
  },
  computed: {
    dialog: {
      get() {
        return this.upLoadFileDialog;
      },
      set() {
        this.$emit("update:upLoadFileDialog", false);
        this.$refs.upload.clearFiles();
      },
    },
  },
  inject: {
    getViewList: "getViewList",
  },
  methods: {
    progress(event) {
      console.log(event);
    },
    handleAvatarSuccess(res) {
      if (res.code === 200) {
        this.$message({
          message: "上传完成!",
          type: "success",
        });
        this.$emit("update:upLoadFileDialog", false);
        this.getViewList();
        this.upLoading = false;
      } else {
        this.$message({
          message: "上传失败!",
          type: "info",
        });
        this.upLoading = false;
        this.$confirm("是否下载失败文件?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let result = await pageViewListErrorReport(res.message);
            download(result, this.$store.getters.formName);
            this.$message({
              type: "success",
              message: "下载成功!",
            });
            this.$emit("update:upLoadFileDialog", false);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消下载",
            });
            this.$emit("update:upLoadFileDialog", false);
          });
      }
      this.$refs.upload.clearFiles();
    },
    async handleDown() {
      let res = await importTemplate(this.$route.query.code);
      download(res, this.$store.getters.formName);
    },
    async requestInfo() {
      if (this.$refs.upload.uploadFiles.length === 0) return;
      this.upLoading = true;
      this.$refs.upload.submit();
    },

    handlePreview(file) {
      const a = document.createElement("a");
      if (file.url) {
        a.href = file.url;
      } else {
        a.href = file.response.url;
      }
      a.download = file.name;
      a.click();
      a.remove();
    },
    beforeUpload(file) {
      const flag = file.size / 1024 / 1024;
      if (flag > 10) {
        this.$message("上传的图片大小必须小于10兆");
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.upload {
  display: flex;
}

/deep/ .upload-demo {
  text-align: center;
}
</style>>
