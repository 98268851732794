<template>
  <el-container style="height: 100vh" v-loading.fullscreen.lock="pageLoading">
    <!-- 切换主菜单 -->
    <el-drawer :visible.sync="menuBarDrawer" direction="ltr" :with-header="false" :modal="false" size="300px" style="top: 52px">
      <ul class="mainMenu">
        <draggable
          class="drag-from"
          :list="mainMenu"
          group="form"
          :options="{
            animation: 300,
            chosenClass: 'choose',
            sort: true,
          }"
          @end="handleSort"
        >
          <li v-for="item in mainMenu" :key="item.value" :style="{ 'background-color': menuBar.name === item.name ? 'rgb(243, 245, 248)' : '#fff' }" @click="handleMenuBar(item)">
            {{ item.name }}
          </li>
        </draggable>
      </ul>
    </el-drawer>

    <el-header>
      <!-- 开启主菜单 -->
      <div style="cursor: pointer; display: flex; align-items: center" @click="menuBarDrawer = !menuBarDrawer">
        <img src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/2024/05/15/20240515155221/logo.png" alt="" style="width: 111px; height: 32px; margin-right: 16px" />
        <i class="el-icon-house"> </i>
      </div>

      <div class="user">
        <el-badge :value="todoNum" :hidden="todoNum < 1" :max="100" style="margin-right: 15px">
          <el-button @click="$router.push('backlog')" icon="el-icon-message-solid" size="small" type="primary" plain>待办中心 </el-button>
        </el-badge>

        <el-button style="margin-right: 15px; margin-top: 5px" size="small" v-if="isAdmin" type="primary" @click="handleDesign">设计表单 </el-button>

        <el-dropdown>
          <div>
            <el-image :src="user.avatar" class="user-avatar" v-if="user.avatar"></el-image>
            <span v-else class="user-avatar">{{ user.name && user.name.split("-").length > 1 ? user.name.split("-")[0].slice(user.name.split("-")[0].length - 2, user.name.split("-")[0].length) : user.name && user.name.slice(user.name.split("-")[0].length - 2, user.name.split("-")[0].length) }}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="$store.dispatch('user/logOut')" v-if="!isDD">退出登录</el-dropdown-item>
            <el-dropdown-item @click.native="browserOpen" v-if="isDD">在浏览器中打开</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <i class="openBtn" :class="isCollapse ? 'el-icon-caret-right' : 'el-icon-caret-left'" @click="isCollapse = !isCollapse" :style="{ left: isCollapse ? '-5px' : '195px' }"></i>
      <el-aside width="200px" v-show="!isCollapse">
        <div class="filter">
          <i class="el-icon-search"></i>
          <el-select v-model="filterPageList" filterable remote clearable reserve-keyword placeholder="请输入关键词" :remote-method="querySearch" :loading="querySearchLoading" value-key="id" @change="handleSelect">
            <el-option-group v-for="group in querySearchOptions" :key="group.id" :label="group.name">
              <el-option v-for="item in group.items" :key="item.id" :label="item.formName" :value="item">
                {{ item.formName }}
              </el-option>
            </el-option-group>
          </el-select>
        </div>
        <el-menu :default-active="defaultActive" :default-openeds="defaultOpeneds" unique-opened>
          <draggable
            :list="list"
            group="list"
            handle=".el-icon-menu"
            :options="{
              animation: 300,
              sort: true,
              scroll: true,
              chosenClass: 'choose',
            }"
            @end="handleItemsSort"
          >
            <el-submenu :index="indexs + 1 + ''" v-for="(items, indexs) in list" :key="items.id">
              <template slot="title">
                <el-dropdown @command="handleCommand($event, items)" v-if="isAdmin">
                  <img style="width: 20px; height: 20px" class="el-icon-menu" src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/移动 竖.png" alt="" />
                  <span class="name">
                    {{ items.name }}
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="add">新增表单</el-dropdown-item>
                    <el-dropdown-item command="edit">重命名</el-dropdown-item>
                    <el-dropdown-item command="del">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div v-else>
                  <i class="el-icon-s-grid"></i>
                  <span class="name">
                    {{ items.name }}
                  </span>
                </div>
              </template>

              <draggable
                :list="items.items"
                group="items"
                handle=".el-icon-more-outline"
                :options="{
                  animation: 300,
                  sort: true,
                  scroll: true,
                  chosenClass: 'choose',
                }"
                @end="handleItemsSort"
              >
                <el-menu-item :index="`${indexs + 1}-${index + 1}`" v-for="(item, index) in items.items" :key="item.formId" @click="handleRouter(item, indexs + 1 + '', `${indexs + 1}-${index + 1}`)">
                  <img style="width: 20px; height: 20px" class="el-icon-more-outline" src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/移动 竖.png" alt="" v-if="isAdmin" />
                  <i class="el-icon-s-grid" v-else></i>
                  <div style="width: 100%; padding-left: 20px; display: flex; align-items: center">
                    <img
                      :src="item.pcLogo ? item.pcLogo : 'https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/2024/04/11/20240411161819/default.svg'"
                      alt=""
                      style="width: 18px; height: 18px; vertical-align: middle; margin-right: 4px"
                      :class="{
                        'svg-image': defaultActive === `${indexs + 1}-${index + 1}` && defaultOpeneds.includes(indexs + 1 + ''),
                      }"
                    />
                    <span>{{ item.formName }}</span>
                  </div>
                  <el-dropdown @command="handleDesignCommand(item, $event)" v-if="isAdmin">
                    <i class="el-icon-more-outline right" style="transform: rotateZ(90deg)"></i>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="design">设计表单</el-dropdown-item>
                      <el-dropdown-item command="copy">复制</el-dropdown-item>
                      <el-dropdown-item command="del">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-menu-item>
              </draggable>
            </el-submenu>
          </draggable>
        </el-menu>
        <div class="addRouter" @click="addGroup" v-if="isAdmin"><i class="el-icon-plus"></i><span>新增</span></div>
      </el-aside>
      <el-container style="overflow: hidden">
        <el-empty description="暂无数据" v-if="!formIds" style="margin: auto"></el-empty>
        <el-main v-else style="height: 100%">
          <div class="homePage">
            <div class="searchCop">
              <Search ref="Search" :searchList="searchList" :sublist="sublist" :defaultTable.sync="defaultTable" :pageNumber.sync="pageNumber" :formData.sync="formData" v-if="tableList.search" />
            </div>
            <div class="contentCop" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="#fff">
              <Content ref="Content" :tableList="tableList" :pageNumber="pageNumber" :pageSize="pageSize" v-if="tableList.field" />
            </div>
          </div>
        </el-main>
        <el-footer :style="{ width: !isCollapse ? 'calc(100vw - 200px)' : '100vw' }">
          <div v-if="isAdmin || user.name === '陈龙'">{{ $version() }}</div>
          <div v-else></div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 20, 50, 100]" :page-size="10" :current-page="pageNumber" layout="total, sizes, prev, pager, next" :total="total"> </el-pagination>
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
import draggable from "vuedraggable";
import { getFormGroups, deleteGroup, updateGroup, copyForm, renameForm, delForm, getLoadView } from "@/api/design";
import Search from "./searchComps/index";
import Content from "./content";
import { getPageViewList } from "@/api/process";
import { getTodoNum } from "@/api/setProcess";
import { moduleListSort, formSearch, getModuleList, formMove } from "@/api/module";
import * as dd from "dingtalk-jsapi";
import { changeTime } from "@/utils/CustomUtil.js";

export default {
  name: "Home",
  components: { Search, Content, draggable },
  data() {
    return {
      formData: {},
      querySearchOptions: [],
      querySearchLoading: false,
      filterPageList: "",
      menuBarDrawer: false,
      menuBar: {
        id: "1",
        name: "",
      },
      expand: "",
      list: [],
      isCollapse: false,
      formIds: "",
      tableList: {}, //表单内容
      loading: false, //表单内容loading
      total: 0,
      pageSize: 10,
      pageNumber: 1,
      searchList: [], //搜索参数
      defaultOpeneds: ["1"],
      defaultActive: "1-1",
      pageLoading: false, //整个页面loading
      todoNum: 0, //待办数量
      defaultTable: "", //子表id
      sublist: [], //子表列表
    };
  },
  computed: {
    mainMenu() {
      return this.$store.state.formData.moduleList;
    },
    user() {
      return this.$store.getters.userInfo;
    },
    isAdmin() {
      return this.$store.getters.userInfo.isAdmin;
    },
    isDD() {
      return dd.env.platform !== "notInDingTalk" ? true : false;
    },
    tableData() {
      return this.$store.state.formData.tableData;
    },
  },
  created() {
    this.setLoginInfo();
  },
  provide() {
    return {
      getViewList: this.getViewList,
    };
  },
  methods: {
    // test() {
    //   window.open(
    //     "http://192.168.2.53:88/#/detail?tempCode=24657341957341641233489&code=8159ff6a36ca03bfdf47650ee43f1dd4&objId=cd696e30-6864-484a-833b-dc6c9b0c638b",
    //     "_blank"
    //   );
    // },
    // 初始化加载
    async setLoginInfo() {
      this.todoNumFn();
      let res = await getModuleList();
      this.$store.commit("formData/setModuleList", res.data);
      let menuBar = JSON.parse(localStorage.getItem("menuBar"));
      if (menuBar) {
        this.menuBar = menuBar;
      } else {
        this.menuBar = res.data[0];
        localStorage.setItem("menuBar", JSON.stringify(this.menuBar));
      }
      this.getList();
    },
    // 获取待办总数
    async todoNumFn() {
      let res = await getTodoNum({
        sort: "",
      });
      if (res.code === 200 && res.data && res.data.length > 0) {
        this.todoNum = res.data.reduce((oldNum, newNum) => {
          return oldNum + newNum.num;
        }, 0);
      }
    },
    // 在钉钉中打开会有在浏览器中打开
    browserOpen() {
      dd.biz.util.openLink({
        url: `http://cloud.yibeirui.com/#/home?code=${this.$route.query.code}&ddToken=${this.$store.getters.token}`,
      });
    },
    // 列表排序
    handleItemsSort() {
      formMove(this.list);
    },
    // 表单筛选切换
    async handleSelect(val) {
      console.log(val);
      if (!val) return;
      this.menuBar = { id: val.moduleId, name: val.moduleName };
      localStorage.setItem("menuBar", JSON.stringify(this.menuBar));
      this.filterPageList = null;
      this.activeRouter(this.list, val.id);
    },

    // 表单筛选搜索
    async querySearch(queryString) {
      this.querySearchLoading = true;
      let res = await formSearch(queryString);
      res.data.forEach((item) => {
        item.items.forEach((items) => {
          items.moduleId = item.id;
          items.moduleName = item.name;
        });
      });
      this.querySearchOptions = res.data;
      this.querySearchLoading = false;
    },
    // 主表单排序
    async handleSort() {
      try {
        await moduleListSort(this.mainMenu);
        this.$message.success("排序成功");
      } catch (error) {
        this.$message.info("排序失败");
      }
    },
    // 主表单切换
    handleMenuBar(item) {
      this.menuBar = { id: item.id, name: item.name };
      this.menuBarDrawer = false;
      localStorage.setItem("menuBar", JSON.stringify(item));
      // 制空路由
      this.$router.push(
        {
          query: {
            code: "",
          },
        },
        () => {}
      );
      this.getList();
    },

    handleSizeChange(val) {
      if (!this.total) return;
      this.pageSize = val;
      this.getViewList(null, null, false);
    },
    handleCurrentChange(val) {
      if (!this.total) return;
      this.pageNumber = val;
      this.getViewList(null, null, false);
    },
    // 添加组名
    addGroup() {
      this.$prompt("请输入要添加的组名", "新的分组名", {
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9\\-]{1,30}$/,
        inputErrorMessage: "分组名不能为空且长度小于30",
        inputPlaceholder: "请输入分组名",
      })
        .then(({ value }) => {
          updateGroup({ name: value, moduleId: this.menuBar.id }).then(() => {
            this.$message.success("新增成功");
            this.getList();
          });
        })
        .catch(() => this.$message.error("已取消"));
    },

    // 表单设置
    async handleDesignCommand(item, e) {
      if (e === "design") {
        this.$router.push(`/admin/design?code=${item.id}`);
      } else if (e === "del") {
        this.$confirm("此操作将永久删除该表单, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            if (item.id === this.$route.query.code) {
              this.formIds = null;
            }
            this.pageLoading = true;
            await deleteGroup(item.id);
            this.$message.success("删除成功");
            this.$router.push(
              {
                query: {
                  code: "",
                },
              },
              () => {}
            );
            this.getList();
            this.pageLoading = false;
          })
          .catch(() => {
            this.$message.info("已取消删除");
          });
      } else {
        this.$confirm("确定复制该表单, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            this.pageLoading = true;
            await copyForm(item.id);
            this.$message.success("复制成功");
            this.getList();
            this.pageLoading = false;
          })
          .catch(() => {
            this.$message.info("已取消复制");
          });
      }
    },
    // 组名设置
    handleCommand(str, item) {
      switch (str) {
        case "add":
          this.$router.push("/admin/design?groupId=" + item.id);
          this.$store.commit("setDataNameList", []);
          break;
        case "edit":
          this.$prompt("请输入新的组名", "重命名", {
            confirmButtonText: "提交",
            cancelButtonText: "取消",
            inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9\\-]{1,30}$/,
            inputErrorMessage: "分组名不能为空且长度小于30",
            inputPlaceholder: "请输入分组名",
            inputValue: item.name,
          })
            .then(async ({ value }) => {
              await renameForm({ name: value, id: item.id });
              this.$message.success("编辑成功");
              this.getList();
            })
            .catch(() => this.$message.error("已取消"));
          break;
        case "del":
          this.$confirm("确定删除该分组, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              this.pageLoading = true;
              await delForm(item.id);
              this.$message.success("删除成功");
              this.$router.push(
                {
                  query: {
                    code: "",
                  },
                },
                () => {}
              );
              this.getList();
              this.pageLoading = false;
            })
            .catch(() => {
              this.$message.info("已取消删除");
            });
          break;
      }
    },
    // 获取页面信息
    async getList() {
      // 请求表单数据
      let res = await getFormGroups(this.menuBar.id || 1);
      // 侧边栏数据
      this.list = res.data;
      // 设置设计表单数据标题
      this.$store.commit("setRouterList", this.list);
      // 无权限板块
      if (!res.data[0]) {
        this.total = 0;
        this.$message.info("您暂时没有该模块权限！");
        this.formIds = "";
        return;
      }
      // 设置侧边栏选中
      this.activeRouter(res.data, this.$route.query.code || "");
    },

    // 获取表单信息
    async getViewList(sort, order, isOne = true) {
      // console.log(sort, order, isOne);
      this.loading = true;
      // 获取默认搜索条件
      if (isOne) {
        let res = await getLoadView({
          formId: this.tableData.id,
        });
        res.data &&
          res.data.forEach((item) => {
            if (item.defaultValue) {
              this.$set(this.formData, item.code, changeTime(item.defaultValue, item.format || "yyyy-MM-dd HH:mm:ss"));
            }
          });
      }
      let result = await getPageViewList({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        formId: this.tableData.formId,
        sort,
        order,
        searchParamsJson: this.formData,
        subFormId: this.defaultTable,
      });
      this.total = result.data.data.total || 0;
      if (this.total <= 10) this.pageNumber = 1;
      this.tableList = result.data;
      // 子表表单
      this.$store.commit("formData/setExternalBtnPermission", result.data.btns || []);
      this.searchList = result.data.search;
      this.sublist = result.data.table;
      this.$store.commit("formData/setRefresh", false);
      this.loading = false;
    },
    // 侧边栏点击
    handleRouter(item, indexs, index) {
      if (item.id === this.formIds) return;
      if (indexs && index) {
        this.defaultOpeneds = [indexs + ""];
        this.defaultActive = index;
      }
      this.pageNumber = 1;
      this.defaultTable = item.id;
      this.$store.commit("formData/setTableData", { ...item, formId: item.id });
      this.formIds = item.id;
      if (!this.formIds) return this.$message.info("该模块没有数据");
      document.title = item.formName;
      // 必须传其中一个，否则会报错
      this.$router.push(
        {
          query: {
            code: this.formIds,
          },
        },
        () => {}
      );
      this.tableList = {};
      this.formData = {};
      this.getViewList();
    },

    // 设置侧边栏选中
    activeRouter(data, id) {
      console.log(data, id);
      const result = id ? id : data[0].items[0].id;
      data.forEach((pItem, pIndex) => {
        pItem.items &&
          pItem.items.forEach((sItem, sIndex) => {
            // console.log(sItem, id);
            if (sItem.id === result) {
              this.handleRouter(sItem, pIndex + 1 + "", `${pIndex + 1}-${sIndex + 1}`);
            }
          });
      });
    },

    // 跳转设计页面
    handleDesign() {
      if (!this.$route.query.code) return;
      this.$router.push("/admin/design?code=" + this.$route.query.code);
    },
  },
};
</script>

<style lang="less" scoped>
/* CSS */
.svg-image {
  filter: drop-shadow(#409eff 150px 0);
  transform: translateX(-150px);
}
.el-header {
  // background-color: #fff;
  background: linear-gradient(180deg, #f9fafd, #e7eef8);
  color: #333;
  line-height: 50px;
  font-size: 16px;
  display: flex;
  height: 52px !important;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
}

.mainMenu {
  padding: 0 12px;
  color: #304265;
  margin-top: 5px;
  li {
    padding: 4px 16px;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    &:hover {
      background-color: rgb(243, 245, 248);
      cursor: pointer;
    }
  }
}

.el-main {
  margin-bottom: 50px;
  min-width: 600px;
  overflow: hidden;
  height: 100vh;
  background: #f5f7fa;
}

.el-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  border-top: 1px solid #e6e6e6;
  height: 52px !important;
  display: flex;
  z-index: 4;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
}

.el-container {
  position: relative;
  overflow: hidden;
  .openBtn {
    position: absolute;
    top: 48%;
    font-size: 22px;
    z-index: 2;
    color: #b3c0d1;
    cursor: pointer;
  }
}

.el-aside {
  color: #333;
  padding-bottom: 50px;
  overflow-x: hidden;
  border-right: 1px solid #e6e6e6;
  padding-top: 50px;
  height: calc(100% - 52px);

  .filter {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 20px;
    background-color: #fff;
    z-index: 2;
    width: 159px;
    border-right: 1px solid #e6e6e6;

    /deep/.el-input__inner {
      border-radius: 20px;
      padding-left: 40px;
      height: 36px;
    }
  }
  .el-icon-search {
    position: absolute;
    z-index: 2;
    left: 28px;
    top: 19px;
    font-size: 20px;
    color: #dcdfe6;
  }
}
.el-icon-more-outline {
  opacity: 0;
}
.disabledName {
  font-weight: 700;
  font-size: 16px;
}
.user {
  display: flex;
  align-items: center;
  cursor: pointer;
  .user-avatar {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #1890ff;
    color: white;
    border-radius: 50%;
    margin-left: 10px;
    display: block;
  }
}
.el-menu {
  border-right: unset !important;
}
.el-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1px !important;

  .icon {
    width: 20px !important;
    height: 50px;
    text-align: center;
    line-height: 50px;
    margin-right: 6px;
  }
  &:hover {
    .el-icon-more-outline {
      opacity: 1;
    }
  }
  // .el-icon-more-outline .right {
  //   margin-left: 100px;
  // }
}
::-webkit-scrollbar {
  width: 0;
}
/deep/ .el-menu-item {
  padding: 0;
}

.el-icon-s-grid {
  opacity: 0;
}

/deep/ .el-submenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &__title {
    padding: 0 !important;
    padding-left: 1px !important;
    display: flex;
    align-items: center;
    .el-icon-menu {
      opacity: 0;
      font-size: 16px;
    }
    .name {
      width: 100%;
      font-weight: 700;
      font-size: 12px;
    }
    .el-dropdown {
      float: right;
    }
    &:hover {
      .el-icon-more-outline,
      .el-icon-menu {
        opacity: 1;
      }
    }
  }
}
.addRouter {
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 200px;
  height: 52px;
  line-height: 50px;
  border-top: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  box-sizing: border-box;
  font-size: 14px;
  z-index: 1;
  background-color: #fff;
  cursor: pointer;

  span {
    margin-left: 5px;
  }
}

/deep/ .el-menu-item.is-active {
  background-color: #ecf5ff;
}

/deep/ .el-card__body,
.el-main {
  padding: 0 14px;
}

.el-icon-house {
  width: 30px;
  height: 30px;
  box-shadow: 1px 1px 10px 0 #d2d2d2;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  margin-right: 10px;
}
::-webkit-scrollbar {
  width: 1;
}

.homePage {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  height: calc(100% - 2px);
  // justify-content: space-between;
  .searchCop {
    height: fit-content;
  }
  .contentCop {
    flex: 1;
    height: 100%;
    overflow: auto;
  }
}
::v-deep .el-badge__content.is-fixed {
  top: 11px;
  right: 15px;
}

.el-badge {
  position: relative;
  img {
    position: absolute;
    top: 20px;
    left: 10px;
  }
}
</style>
