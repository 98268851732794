export function download(res, name) {
    var blob = new Blob([res])
    if ('download' in document.createElement('a')) {
        // 非IE下载
        const elink = document.createElement('a')
        elink.download = `${name}明细表.xlsx`
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
    } else {
        // IE10+下载
        navigator.msSaveBlob(blob, `${name}明细表.xlsx`) // 支持用户在浏览器下载文件 提供一个保存的方法
    }
}