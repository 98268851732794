var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tableList)?_c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.pageLoading),expression:"pageLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"contentPage",attrs:{"element-loading-text":"拼命加载中"}},[_c('div',{staticClass:"contentTable"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"height":"100%"},attrs:{"data":_vm.tableList.data.records,"row-style":_vm.rowStyle,"cell-style":_vm.cellStyle,"stripe":"","border":"","header-cell-style":_vm.headerCellStyle},on:{"sort-change":_vm.handleSort,"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"50","align":"center"}}),_c('el-table-column',{attrs:{"type":"index","width":"60","align":"center","label":"序号","index":_vm.seIindex}}),_c('el-table-column',{attrs:{"prop":"Name","label":"数据标题","align":"center","width":_vm.flexColumnWidth('数据标题', _vm.tableList.data.records, 'Name')},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{on:{"click":function($event){return _vm.drawerPage(row)}}},[_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"href":"#"}},[_c('ellipsis',{attrs:{"hoverTip":"","row":1,"content":row.Name}})],1)])]}}],null,false,1886377315)}),_vm._l((_vm.tableList.field),function(item){return _c('el-table-column',{key:item.itemId,attrs:{"sortable":_vm.sortable(item),"prop":item.code,"label":item.title,"align":"center","width":['Created', 'Updated', 'DateTime'].includes(item.code)
            ? '180px'
            : _vm.tableList.field.length < 5
            ? 'auto'
            : _vm.flexColumnWidth(item.title, _vm.tableList.data.records, item.code),"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{class:{ relevance: _vm.isToDetail.includes(item.name) },staticStyle:{"display":"block","word-break":"break-all","overflow":"hidden","text-overflow":"ellipsis","-webkit-line-clamp":"1","-webkit-box-orient":"vertical","white-space":"nowrap"},on:{"click":function($event){return _vm.toDetail(row, item)}}},[_vm._v(" "+_vm._s(row[item.code])+" ")])]}}],null,true)},_vm._l((item.items),function(k){return _c('el-table-column',{key:k.code,attrs:{"prop":k.code,"label":k.title,"align":"center","min-width":['Created', 'Updated', 'DateTime'].includes(k.code)
              ? '180px'
              : _vm.flexColumnWidth(
                  k.title,
                  _vm.tableList.data.records[item.code],
                  k.code
                ),"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._l((row[k.formId].slice(0, 4)),function(num,i){return _c('div',{key:i},[_c('div',[(
                    row[k.formId][i][k.code] &&
                    ['png', 'jpg'].includes(
                      row[k.formId][i][k.code]
                        .toString()
                        .substring(
                          row[k.formId][i][k.code].toString().length - 3
                        )
                    )
                  )?_c('el-image',{staticStyle:{"width":"80px","height":"80px"},attrs:{"src":row[k.formId][i][k.code],"alt":""}}):_c('div',[_vm._v(" "+_vm._s(row[k.formId][i][k.code])+" ")])],1)])}),(row[k.formId].length > 4)?_c('div',[_vm._v("······")]):_vm._e()]}}],null,true)})}),1)})],2)],1),_c('el-dialog',{attrs:{"title":"人员信息","visible":_vm.userInfoDialog,"width":"600px"},on:{"update:visible":function($event){_vm.userInfoDialog=$event}}},[(_vm.userInfoDialog)?_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"display":"flex","margin":"auto"}},[(_vm.userInfo.avatar)?_c('el-image',{staticStyle:{"width":"150px","height":"150px","border-radius":"20px"},attrs:{"src":_vm.userInfo.avatar}}):_c('span',{staticStyle:{"text-align":"center","line-height":"150px","background":"#1890ff","color":"white","margin-left":"10px","display":"block","width":"150px","height":"150px","border-radius":"20px","font-size":"40px"}},[_vm._v(_vm._s(_vm.userInfo.name && _vm.userInfo.name.split("-").length > 1 ? _vm.userInfo.name .split("-")[0] .slice( _vm.userInfo.name.split("-")[0].length - 2, _vm.userInfo.name.split("-")[0].length ) : _vm.userInfo.name.slice( _vm.userInfo.name.length - 2, _vm.userInfo.name.length )))]),_c('div',{staticStyle:{"margin-left":"100px"}},[_c('ul',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-between","height":"100%"}},[_c('li',[_vm._v("姓名："+_vm._s(_vm.userInfo.name))]),_c('li',[_vm._v("员工工号："+_vm._s(_vm.userInfo.jobNum))]),_c('li',[_vm._v("职位："+_vm._s(_vm.userInfo.position))]),_c('li',[_vm._v("部门："+_vm._s(_vm.userInfo.deptName))]),_c('li',[_vm._v("联系方式："+_vm._s(_vm.userInfo.phone))])])])],1)]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }