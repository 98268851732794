<template>
  <div>
    <div class="buttons">
      <div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="drawerPage"
          v-loading.fullscreen.lock="loading"
          element-loading-text="拼命加载中"
          v-if="btnPermission.includes('SUBMIT')"
          >新增</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-delete"
          size="small"
          @click="handleDelList"
          v-if="btnPermission.includes('DELETE')"
          >删除</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-folder-add"
          size="small"
          @click="upLoadFile"
          v-if="btnPermission.includes('IMPORT')"
          >导入</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-document-copy"
          size="small"
          @click="handleExport"
          :loading="exportLoading"
          v-if="btnPermission.includes('EXPORT')"
          >导出</el-button
        >
      </div>
      <div style="float: right; width: fit-content">
        <el-tooltip
          class="item"
          effect="dark"
          :content="title.remark"
          placement="top"
        >
          <img
            src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/问号.png"
            alt=""
            width="20px"
            height="20px"
            style="vertical-align: middle; margin-right: 20px"
          />
        </el-tooltip>

        <el-select
          v-if="sublist.length > 1"
          v-model="_defaultTable"
          placeholder="请选择"
          size="small"
          style="width: 100px; margin-right: 20px"
        >
          <el-option
            v-for="item in sublist"
            :key="item.value"
            :label="item.formName"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-button
          type="primary"
          style="width: 80px"
          icon="el-icon-refresh-right"
          size="small"
          @click="queryList"
          >刷新</el-button
        >
      </div>
    </div>

    <div
      class="form"
      :style="{ height: isSwitch ? '100%' : '60px' }"
      v-if="searchList.length > 0"
    >
      <el-form
        :model="formData"
        :inline="true"
        label-position="left"
        class="formData"
        :class="{ isRoll: isSwitch }"
      >
        <el-form-item
          v-for="item in searchList"
          :key="item.code"
          :label="item.title"
          label-width="100px"
          style="margin-left: 20px"
        >
          <template #label>
            <el-popover
              v-if="item.title.length > 6"
              placement="top-start"
              :width="item.title.length * 12"
              trigger="hover"
              :content="item.title"
            >
              <div
                slot="reference"
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 100px;
                "
              >
                {{ item.title }}
              </div>
            </el-popover>
            <div v-else>{{ item.title }}</div>
          </template>
          <component
            :is="item.name"
            :item="item"
            :formData="formData"
            @queryList="queryList"
          >
          </component>
        </el-form-item>
      </el-form>

      <div class="switch">
        <el-button
          style="width: 80px; margin-right: 20px"
          icon="el-icon-refresh-right"
          size="small"
          @click="resetQueryList"
          >重置</el-button
        >
        <el-button
          style="width: 80px"
          :icon="`el-icon-arrow-${isSwitch ? 'up' : 'down'}`"
          size="small"
          @click="isSwitch = !isSwitch"
          >{{ isSwitch ? "收起" : "展开" }}</el-button
        >
      </div>
    </div>
    <FileUpLoad :upLoadFileDialog.sync="upLoadFileDialog"></FileUpLoad>
  </div>
</template>

<script>
import FileUpLoad from "@/components/common/FileUpLoad";
import components from "@/views/home/CompExport";
import { pageListDel } from "@/api/drawerPage";
import { pageViewListExport } from "@/api/process";
import { download } from "@/utils/export.js";

export default {
  components: { ...components, FileUpLoad },
  props: {
    searchList: {
      type: Array,
      default: () => [],
    },
    defaultTable: {
      type: String,
      default: "",
    },
    sublist: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
    pageNumber: {
      type: Number,
      default: 1,
    },
  },

  inject: ["getViewList"],
  data() {
    return {
      upLoadFileDialog: false,
      exportLoading: false,
      loading: false,
      isSwitch: false,
    };
  },
  computed: {
    btnPermission() {
      return this.$store.state.formData.externalBtnPermission;
    },
    title() {
      return this.$store.state.formData.tableData;
    },
    _defaultTable: {
      get() {
        return this.defaultTable;
      },
      set(val) {
        this.$emit("update:defaultTable", val);
        this.getViewList(null, null, false);
      },
    },
    refresh() {
      return this.$store.state.formData.refresh;
    },
    tableData() {
      return this.$store.state.formData.tableData;
    },
  },
  watch: {
    refresh(val) {
      if (val) {
        this.$router.push(
          {
            query: {
              code: this.tableData.formId,
            },
          },
          () => {}
        );
        this.queryList();
      }
    },
  },
  methods: {
    async handleExport() {
      let data = {
        formId: this.$route.query.code,
        pageNumber: 1,
        pageSize: 9999,
        searchParamsJson: this.formData,
        subFormId: this.$store.state.tableChecked,
      };
      this.exportLoading = true;
      const res = await pageViewListExport(data);
      download(res, this.$store.getters.formName);
      this.$message.success("导出成功");
      this.exportLoading = false;
    },
    upLoadFile() {
      this.upLoadFileDialog = true;
    },
    handleDelList() {
      if (this.$store.state.formData.multipleSelection.length < 1) return;
      this.$confirm("此操作将永久删除该表单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = [];
          this.$store.state.formData.multipleSelection.forEach((item) => {
            res.push(item.id);
          });
          if (res.length > 1) this.$emit("update:pageNumber", 1);
          await pageListDel(this.$route.query.code, res);
          this.getViewList(null, null, false);
          this.$message.success("删除成功!");
        })
        .catch(() => {
          this.$message.info("取消删除!");
        });
    },
    async drawerPage() {
      this.loading = true;
      await this.$store.dispatch("formData/getDetailData", {
        formId: this.$route.query.code,
        type: "add",
      });
      this.loading = false;
    },
    queryList() {
      this.getViewList(null, null, false);
    },
    resetQueryList() {
      this.searchList.forEach((item) => {
        this.$set(this.formData, item.code, null);
      });
      this.getViewList(null, null, false);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  height: 36px;
}
/deep/ .el-input--small .el-input__inner {
  height: 32px;
}
// /deep/ .el-form--inline .el-form-item__label {
//   white-space: normal;
// }
/deep/ .el-form-item__content {
  line-height: 36px;
  width: calc(100% - 100px);
}
.buttons {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form {
  background-color: #fff;
  padding: 20px 0 40px;
  position: relative;
  overflow: hidden;
  .isRoll {
    max-height: 360px;
    overflow: auto;
  }
  .switch {
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
    z-index: 2;
  }
}
@media only screen and (max-width: 900px) {
  .el-form-item {
    width: calc(100% - 30px);
  }
}

@media only screen and (min-width: 900px) {
  .el-form-item {
    width: calc(50% - 30px);
  }
}

@media only screen and (min-width: 1400px) {
  .el-form-item {
    width: calc(33% - 30px);
  }
}

@media only screen and (min-width: 1920px) {
  .el-form-item {
    width: calc(25% - 30px);
  }
}

/deep/ .el-button:focus {
  color: #606266;
  border-color: #dcdfe6;
  background-color: unset;
}
/deep/ .el-button:hover {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
</style>